import logo from './logo.svg';
import './App.css';
import HomePg from './Pages/HomePg';
import Contact from './Pages/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';
import About from './Pages/About';
import WebServicePG from './Pages/WebServicePG';
import MobileServicePage from './Pages/MobileServicePage';
import GameServicePg from './Pages/GameServicePg';
import BlockchainServicePG from './Pages/BlockchainServicePG';
import AIServicesPG from './Pages/AIServicesPG';
import CyberSecurityPg from './Pages/CyberSecurityPg';
import ScrollToTop from './ScrollTop';
import Comingsoon from './Components/Comingsoon';
function App() {
  return (
    <div>
      {/* <HomePg/> */}
      <Routes>
      {/* <ScrollToTop /> */}
        <Route path='/' element={<HomePg/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/services/web' element={<WebServicePG/>}/>
        <Route path='/services/mobileapp' element={<MobileServicePage/>}/>
        <Route path='/services/game' element={<GameServicePg/>}/>
        <Route path='/services/blockchain' element={<BlockchainServicePG/>}/>
        <Route path='/services/ai' element={<AIServicesPG/>}/>
        <Route path='/services/cyber' element={<CyberSecurityPg/>}/>
        <Route path='/notfound' element={<Comingsoon/>}/>
        {/* <Route path='/about' element={<AboutPg/>}/> */}
      </Routes>
    </div>
  );
}

export default App;
