import React, {useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faCrosshairs, faPen } from '@fortawesome/free-solid-svg-icons';


function Mission() {
    useEffect(() => {
        const slideInOnScroll = () => {
          const cols = document.querySelectorAll('.col-abt, .col-uni-abt');
          cols.forEach(col => {
            const colTop = col.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            if (colTop < windowHeight - 50) { // Adjust threshold as needed
              col.classList.add('slide-in');
            }
          });
        };
    
        // Attach event listener on scroll
        window.addEventListener('scroll', slideInOnScroll);
    
        // Initial check on page load
        slideInOnScroll();
    
        // Clean up listener on component unmount
        return () => {
          window.removeEventListener('scroll', slideInOnScroll);
        };
      }, []);
  return (
    <div>
        <div className='services-home-con-about'>
          <div className='row1'>
            <div className='col-abt'>
              <FontAwesomeIcon icon={faLightbulb} className='icon-ser-abt'/>
              <h3>OUR VISION</h3>
              <p>Our vision is to be a global leader in software development, creating impactful digital solutions that enhance global networks and drive progress. We aspire to be recognized for our innovation, excellence, and commitment to client satisfaction.</p>
            </div>
            <div className='col-uni-abt'>
            <FontAwesomeIcon icon={faCrosshairs} className='icon-ser-abt'/>
              <h3>OUR MISSION</h3>
              <p>Our mission is to deliver innovative and high-quality software solutions that exceed client expectations, fostering long-term relationships based on trust and satisfaction. We aim to drive business growth and efficiency through excellence and continuous improvement.</p>
            </div>
            <div className='col-abt'>
            <FontAwesomeIcon icon={faPen} className='icon-ser-abt'/>
              <h3>OUR MOTTO</h3>
              <p>Crafting Excellence, Every Project.</p>
            </div>
          </div>

          
       
        
        </div>
    </div>
  )
}

export default Mission