import React, {useEffect} from 'react'
import './Home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faGamepad,faMobileScreen, faChain, faBrain, faUserShield } from '@fortawesome/free-solid-svg-icons';
function ServiceHome() {
    useEffect(() => {
        const slideInOnScroll = () => {
          const cols = document.querySelectorAll('.col, .col-uni');
          cols.forEach(col => {
            const colTop = col.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            if (colTop < windowHeight - 50) { // Adjust threshold as needed
              col.classList.add('slide-in');
            }
          });
        };
    
        // Attach event listener on scroll
        window.addEventListener('scroll', slideInOnScroll);
    
        // Initial check on page load
        slideInOnScroll();
    
        // Clean up listener on component unmount
        return () => {
          window.removeEventListener('scroll', slideInOnScroll);
        };
      }, []);
  return (
    <div className='main-service'>
    <div className='services-home-con'>
          <div className='row1'>
            <div className='col'>
              <FontAwesomeIcon icon={faGlobe} className='icon-ser'/>
              <h3>WEB DEVELOPMENT</h3>
              <p>Innovating through web solutions.</p>
            </div>
            <div className='col-uni'>
            <FontAwesomeIcon icon={faMobileScreen} className='icon-ser'/>
              <h3>APP DEVELOPMENT</h3>
              <p>Empowering Life with Mobile Innovations.</p>
            </div>
            <div className='col'>
            <FontAwesomeIcon icon={faGamepad} className='icon-ser'/>
              <h3>GAME DEVELOPMENT</h3>
              <p>Level Up Your Gaming Experience!</p>
            </div>
          </div>

          
       
        {/* <div className='services-home-con2'> */}
            <div className='row1'>
            <div className='col'>
            <FontAwesomeIcon icon={faChain} className='icon-ser' />
              <h3>BLOCKCHAIN DEVELOPMENT</h3>
              <p>Revolutionizing Trust with Blockchain Technology.</p>
            </div>
            <div className='col-uni'>
            <FontAwesomeIcon icon={faBrain} className='icon-ser'/>
              <h3>ARTIFICIAL INTELLIGENCE</h3>
              <p>Empowering Future with Artificial Intelligence.</p>
            </div>
            <div className='col'>
            <FontAwesomeIcon icon={faUserShield} className='icon-ser'/>
              <h3>CYBER SECURITY</h3>
              <p>Protecting Your Digital Frontier Securely.</p>
            </div>
          </div>
        </div>
        </div>
        // </div>
  )
}

export default ServiceHome