import React from 'react'
import AIHeader from '../Components/AIHeader'
import DescriptionAI from '../Components/DescriptionAI'
import Work from '../Components/Work'
import Rate from '../Components/Rate'
import Choose from '../Components/Choose'
import Footer1 from '../Components/Footer1'
function AIServicesPG() {
  return (
    <div style={{backgroundColor:'#1C1C1C', width:'100%', height:'max-content'}}>
    <AIHeader/>
    <DescriptionAI/>
   <Work/>
   <Rate/>
   <Choose/>
   <Footer1/>
   </div>
  )
}

export default AIServicesPG