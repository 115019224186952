import React from 'react'
import MobileHeader from '../Components/MobileHeader'
import DescriptionMob from '../Components/DescriptionMob'
import Work from '../Components/Work'
import Rate from '../Components/Rate'
import Choose from '../Components/Choose'
import Footer1 from '../Components/Footer1'
function MobileServicePage() {
  return (
    <div style={{backgroundColor:'#1C1C1C', width:'100%', height:'max-content'}}>
     <MobileHeader/>
     <DescriptionMob/>
    <Work/>
    <Rate/>
    <Choose/>
    <Footer1/>
  
</div>
  )
}

export default MobileServicePage