import React from 'react'
import Navbar1 from './Navbar1'
import Footer1 from './Footer1'
import './Cominsoon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
function Comingsoon() {
  return (
    <div style={{backgroundColor:'#1C1C1C', width:'100%', height:'max-content'}}>
        <Navbar1/>
        <div className='main-div-soon'>
        <h1>Coming Soon!</h1>
        <FontAwesomeIcon icon={faSmile} className='icon-soon'/>
        </div>
        <Footer1/>
    </div>
  )
}

export default Comingsoon