// src/Testimonial.js
import React, { useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './Tesimonial.css';
import Character3D from '../Images/tesimonial.png'
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Testimonial = () => {
  const navigate=useNavigate;
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      offset: 100, // Offset from the original trigger point
      once: true, // Whether animation should happen only once - while scrolling down
      easing: 'ease-in-out', // Easing function
    });
  }, []);
  const testimonials = [
    {
      name: 'Angela Molina',
      title: 'CEO Nice',
      text: 'Ex donec class in vehicula tellus fames mattis elementum. Ultrices cras vel mus ex netus odio nulla eleifend scelerisque ornare. Vehicula ultrices luctus quisque porta finibus nibh.',
      img: 'path_to_image_1' // Replace with actual image path or URL
    },
    {
      name: 'Jamie Richardson',
      title: 'Founder Lokamart',
      text: 'Ex donec class in vehicula tellus fames mattis elementum. Ultrices cras vel mus ex netus odio nulla eleifend scelerisque ornare. Vehicula ultrices luctus quisque porta finibus nibh.',
      img: 'path_to_image_2' // Replace with actual image path or URL
    },
    {
      name: 'Alex Doe',
      title: 'Manager ExampleCorp',
      text: 'Aenean quam. In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula vel nunc egestas porttitor.',
      img: 'path_to_image_3' // Replace with actual image path or URL
    },
    {
      name: 'Chris Lee',
      title: 'Developer TechSoft',
      text: 'Curabitur at lacus ac velit ornare lobortis. Curabitur a felis in nunc fringilla tristique. Fusce egestas elit eget lorem.',
      img: 'path_to_image_4' // Replace with actual image path or URL
    }
  ];

  return (
    <div className="main-con-tesimonial">
      {/* <div className="mob-tesimonial"> */}
        <div className='grid-tes'>
          <div className='content-tesimonial' data-aos="fade-right">
            <h6>TESIMONIAL</h6>
          </div>
          <div className='fs-div1-inner2' data-aos="fade-right">
            <h2 className='tes-h2'>What they say about us</h2>
            <p className='tes-p'>Discover the stories that inspire: glowing testimonials that speak volumes about our dedication and quality.
            </p>
          </div>

         <div className='mob-cursal'>
         <Carousel
    showArrows={false}
    showThumbs={false}
    showStatus={false}
    infiniteLoop={true}
    autoPlay={true}
    interval={5000}
    centerMode={true}
    centerSlidePercentage={100}
    className="carousel-mob"
    data-aos="fade-up"
  >
    {testimonials.map((testimonial, index) => (
      <div key={index} className="testimonial-card" data-aos="fade-up">
        <p>{testimonial.text}</p>
        <div className="testimonial-author">
          {/* <img src={testimonial.img} alt={testimonial.name} /> */}
          <div className="author-info">
            <h3>{testimonial.name}</h3>
            <p>{testimonial.title}</p>
          </div>
        </div>
      </div>
    ))}
  </Carousel>
          </div> 
          <div className='image-main-tesimonial'>
            <div className='image-character'>
              <div className='img-character' data-aos="fade-left">
                <img src={Character3D} alt='Tesimonial' className='real-img-character' />
              </div>
            </div>
          </div>
        </div>

      {/* </div> */}

      <div className='inner1-con-tesimonial'>
        <div className='flex-tes'>
          <div className='col1-tesimonial'>
            <div className='content-tesimonial' data-aos="fade-right">
              <h6>TESIMONIAL</h6>
            </div>
            <div className='fs-div1-inner2' data-aos="fade-right">
              <h2 className='tes-h2'>What they say about us</h2>
              <p className='tes-p'>Discover the stories that inspire: glowing testimonials that speak volumes about our dedication and quality.
              </p>
            </div>

            <Carousel
              showArrows={false}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={5000}
              centerMode={true}
              centerSlidePercentage={50}
              className="carousel"
              data-aos="fade-up"
            >
              {testimonials.map((testimonial, index) => (
                <div key={index} className="testimonial-card" data-aos="fade-up">
                  <p>{testimonial.text}</p>
                  <div className="testimonial-author">
                    {/* <img src={testimonial.img} alt={testimonial.name} /> */}
                    <div className="author-info">
                      <h3>{testimonial.name}</h3>
                      <p>{testimonial.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>

          </div>

          <div className='image-main-tesimonial'>
            <div className='image-character'>
              <div className='img-character' data-aos="fade-left">
                <img src={Character3D} alt='Tesimonial' className='real-img-character' />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <h2>What they say about us</h2>
      <p>Pellentesque tincidunt litora suspendisse est fames sapien a consequat maximus.</p>
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        centerMode={true}
        centerSlidePercentage={50}
      >
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <p>{testimonial.text}</p>
            <div className="testimonial-author">
              <img src={testimonial.img} alt={testimonial.name} />
              <div>
                <h3>{testimonial.name}</h3>
                <p>{testimonial.title}</p>
              </div>
            </div>
          </div>
        ))}
      </Carousel> */}
    </div>
  );
};

export default Testimonial;
