import React from 'react'
import Aboutcmp from '../Components/Aboutcmp'
import Who from '../Components/Who'
import Rate from '../Components/Rate'
import Footer1 from '../Components/Footer1'
import Mission from '../Components/Mission'
import Work from '../Components/Work'
function About() {
  return (
    <div style={{backgroundColor:'#1C1C1C', width:'100%', height:'max-content'}}>
        <Aboutcmp/>
        <Who/>
        <Mission/>
        <Rate/>
        <Work/>
        <Footer1/>
        </div>
  )
}

export default About