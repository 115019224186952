import React from 'react'
import './DescriptionWeb.css';
import Accordion from 'react-bootstrap/Accordion';

function DescriptionMob() {
  return (
    <div className='main-desc-con'>
            <div className='inner-desc-con'>
                <div className='des-content-con'>
                    <h2>Description</h2>
                    <p>At MegaTech AI Solutions, we specialize in creating innovative and high-performance mobile applications using Flutter, a powerful framework that enables seamless development for both iOS and Android platforms. Our approach is driven by a deep understanding of your project requirements and specifications, ensuring that the technology we use aligns perfectly with your business goals. Whether you're looking for a sleek, user-friendly app or a complex, feature-rich solution, we leverage Flutter’s capabilities to deliver exceptional results that meet your unique needs.</p>
                    <h2 style={{ marginTop: '30px' }}>What we do</h2>
                    <Accordion id='Accordian-main'>
        <Accordion.Item eventKey="0" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Comprehensive Mobile App Development</Accordion.Header>
            <Accordion.Body>
            At MegaTech AI Solutions, we specialize in delivering high-quality mobile applications using Flutter technology. Our team crafts intuitive and engaging mobile experiences tailored to your business needs, ensuring optimal performance across both Android and iOS platforms.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Tailored Solutions for Your Needs</Accordion.Header>
            <Accordion.Body>
            We understand that every project has unique requirements. Our mobile app development process involves selecting the most suitable technology based on your project specifications, ensuring that we meet your goals efficiently and effectively.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>End-to-End Development Process</Accordion.Header>
            <Accordion.Body>
            From conceptualization and design to development and deployment, we handle every aspect of mobile app development. Our team ensures that your app is delivered on time, within budget, and with the highest quality standards.
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
                </div>
                <div className='inner-desc-con2'>
                    <h4>Get free a quote</h4>
                    <form className='form'>
                        <label>Name</label>
                        <input type='text' placeholder='Enter your name' required />
                        <label>Email</label>
                        <input type='email' placeholder='Enter your email' required />
                        <label>Message</label>
                        <textarea placeholder='Enter your message' required />
                        <button type='submit'>Get a quote</button>
                    </form>
                </div>
            </div>

        </div>
  )
}

export default DescriptionMob