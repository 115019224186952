import React from 'react'
import './DescriptionWeb.css';
import Accordion from 'react-bootstrap/Accordion';

function DescriptionWeb() {
    return (
        <div className='main-desc-con'>
            <div className='inner-desc-con'>
                <div className='des-content-con'>
                    <h2>Description</h2>
                    <p>At MegaTech AI Solutions, we offer a comprehensive suite of web application services designed to meet the diverse needs of our clients. Our expertise spans the latest technologies, ensuring we deliver high-quality, scalable, and efficient solutions.
                        <br /><br />
                        Our web development services leverage the power of the MEAN and MERN stacks, allowing us to build robust, full-stack applications that are both dynamic and responsive. Whether it's MongoDB, Express.js, Angular, Node.js (MEAN) or MongoDB, Express.js, React, Node.js (MERN), our team is adept at using the right tools to create seamless user experiences.
                        <br /><br />
                        For projects requiring server-side rendering and enhanced performance, we utilize Next.js, ensuring fast load times and improved SEO. This allows us to deliver cutting-edge, high-performance web applications tailored to your needs.
                        <br /><br />
                        Additionally, we specialize in using the Django Rest Framework for backend development. This powerful framework enables us to create secure, scalable, and maintainable APIs, providing a solid foundation for your web applications.
                        <br /><br />
                        Our approach is client-centric; we select the most appropriate technology stack based on your project's specific requirements and specifications. By combining our technical expertise with a deep understanding of your business goals, we deliver solutions that drive success and growth.</p>
                    <h2 style={{ marginTop: '30px' }}>What we do</h2>
                    <Accordion id='Accordian-main'>
        <Accordion.Item eventKey="0" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Full-Stack Development</Accordion.Header>
            <Accordion.Body>
                We offer end-to-end full-stack development services using the MEAN and MERN stacks. Our team builds robust, scalable applications with MongoDB, Express.js, Angular, and Node.js (MEAN) or MongoDB, Express.js, React, and Node.js (MERN), ensuring seamless user experiences and high performance.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Server-Side Rendering with Next.js</Accordion.Header>
            <Accordion.Body>
                For enhanced performance and SEO benefits, we utilize Next.js for server-side rendering. This technology allows us to deliver fast-loading, high-performance web applications, providing an improved user experience and better search engine visibility.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Customized Solutions</Accordion.Header>
            <Accordion.Body>
                At MegaTech AI Solutions, we tailor our services to meet the unique needs of each client. Whether you need a dynamic single-page application, a complex multi-page platform, or a custom API, we select the most suitable technology stack and approach based on your specific project requirements and goals.
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
                </div>
                <div className='inner-desc-con2'>
                    <h4>Get free a quote</h4>
                    <form className='form'>
                        <label>Name</label>
                        <input type='text' placeholder='Enter your name' required />
                        <label>Email</label>
                        <input type='email' placeholder='Enter your email' required />
                        <label>Message</label>
                        <textarea placeholder='Enter your message' required />
                        <button type='submit'>Get a quote</button>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default DescriptionWeb