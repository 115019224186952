import React from 'react'
import Navbar1 from './Navbar1'
import {ReactTyped} from 'react-typed';
import './Home.css'

import ServiceHome from './ServiceHome';
function Home() {
  return (
    <div className='main-home-div'>
        <Navbar1/>
        <div className='slogan-home'>
            <h1>Innovate, Secure, and Transform with Us: Pioneering Excellence in <span> 
            <ReactTyped showCursor={false}
              strings={[
                'Web Development', 
                'Mobile App Development', 
                'Game Development',
                'AI Development', 
                'Blockchain Development', 
                'Cybersecurity Services'
              ]}
              typeSpeed={40}
              backSpeed={50}
              loop
            />
          </span></h1>
        </div>
       
    </div>
  )
}

export default Home