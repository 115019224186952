import React from 'react'
import Home from '../Components/Home'
import ServiceHome from '../Components/ServiceHome'
import Who from '../Components/Who'
import Choose from '../Components/Choose'
import Rate from '../Components/Rate'
import Tesimonial from '../Components/Tesimonial'
import Footer1 from '../Components/Footer1'
// import Consult from '../Components/Consult'
function HomePg() {
  return (
    <div style={{backgroundColor:'#1C1C1C', width:'100%', height:'max-content'}}>
        <Home/>
        <ServiceHome/>
        <Who/>
        <Choose/>
        <Rate/>
        <Tesimonial/>
        {/* <Consult/> */}
        <Footer1/>
    </div>
  )
}

export default HomePg