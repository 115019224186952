import React from 'react'
import './DescriptionWeb.css';
import Accordion from 'react-bootstrap/Accordion';

function DescriptionBlockchain() {
  return (
    <div className='main-desc-con'>
            <div className='inner-desc-con'>
                <div className='des-content-con'>
                    <h2>Description</h2>
                    <p>
             

At our company, we deliver cutting-edge blockchain solutions tailored to your specific project needs. Our services encompass the design, development, and deployment of blockchain systems, ensuring that we utilize the most appropriate technology stack for your unique requirements. Whether you're looking to enhance transparency, improve security, or streamline processes, our team leverages the latest advancements in blockchain technology to provide innovative and reliable solutions. From smart contracts to decentralized applications (dApps), we craft bespoke solutions that drive efficiency and create value for your business.</p>
                    <h2 style={{ marginTop: '30px' }}>What we do</h2>
                    <Accordion id='Accordian-main'>
        <Accordion.Item eventKey="0" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'> Blockchain Development Overview</Accordion.Header>
            <Accordion.Body>
            At MegaTech AI Solutions, we specialize in delivering innovative blockchain development services tailored to meet the unique needs of our clients. Our expertise encompasses a range of blockchain technologies and platforms to ensure secure, transparent, and efficient solutions.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Custom Blockchain Solutions</Accordion.Header>
            <Accordion.Body>
            We design and develop custom blockchain solutions tailored to your business requirements. Whether you're looking for a private blockchain, a consortium chain, or a public blockchain, we provide the expertise to create a secure and scalable system that meets your specific needs.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Smart Contracts</Accordion.Header>
            <Accordion.Body>
            Our team excels in developing and deploying smart contracts that automate and enforce business processes on the blockchain. We ensure these contracts are secure, efficient, and optimized for your specific use case.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Blockchain Integration</Accordion.Header>
            <Accordion.Body>
            We offer seamless integration of blockchain technology into your existing systems and workflows. Our integration services enhance transparency, security, and efficiency, ensuring your business can fully leverage the benefits of blockchain technology.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Blockchain Consulting</Accordion.Header>
            <Accordion.Body>
            Our blockchain consulting services provide you with expert guidance on blockchain strategy, implementation, and optimization. We help you understand the potential applications of blockchain in your industry and develop a roadmap for successful adoption.
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
                </div>
                <div className='inner-desc-con2'>
                    <h4>Get free a quote</h4>
                    <form className='form'>
                        <label>Name</label>
                        <input type='text' placeholder='Enter your name' required />
                        <label>Email</label>
                        <input type='email' placeholder='Enter your email' required />
                        <label>Message</label>
                        <textarea placeholder='Enter your message' required />
                        <button type='submit'>Get a quote</button>
                    </form>
                </div>
            </div>

        </div>
  )
}

export default DescriptionBlockchain