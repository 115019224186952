import React from 'react'
import BlockchainHeader from '../Components/BlockchainHeader'
import Work from '../Components/Work'
import Rate from '../Components/Rate'
import Choose from '../Components/Choose'
import Footer1 from '../Components/Footer1'
import DescriptionBlockchain from '../Components/DescriptionBlockchain'

function BlockchainServicePG() {
  return (
    <div style={{backgroundColor:'#1C1C1C', width:'100%', height:'max-content'}}>
    {/* <MobileHeader/>
    <DescriptionMob/> */}
    <BlockchainHeader/>
    <DescriptionBlockchain/>
   <Work/>
   <Rate/>
   <Choose/>
   <Footer1/>
 
</div>
  )
}

export default BlockchainServicePG