import React, { useState, useEffect } from 'react';
import './ContactForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhoneVolume, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { submitContactForm } from '../Services/api.js'; // Import API service

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [borderColor, setBorderColor] = useState('transparent');
  const [submissionStatus, setSubmissionStatus] = useState('');

  const handleBorder = () => {
    setBorderColor('#205cd4');
  };

  const handleBlur = () => {
    setBorderColor('transparent');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const contactData = { name, email, message };
  
    try {
      const result = await submitContactForm(contactData);
      setSubmissionStatus('Message sent successfully!');
      // Reset form fields
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      setSubmissionStatus('Failed to send message. Please try again.');
    }
  };
  

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
      once: true,
      easing: 'ease-in-out',
    });
  }, []);

  return (
    <div className="main-contact-form">
      <div className="inner-contact-form">
        <div className="form-col" data-aos="fade-right">
          <h2>Send us a message</h2>
          <p>
            Get in touch with our expert team at Megatech Ai Solutions for
            innovative software solutions tailored to your needs. Contact us
            today to start your project!
          </p>
          <form onSubmit={handleSubmit}>
            <label>Name</label>
            <input
              type="text"
              className="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onFocus={handleBorder}
              onBlur={handleBlur}
              required
            />
            <label>Email</label>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={handleBorder}
              onBlur={handleBlur}
              required
            />
            <label>Message</label>
            <textarea
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onFocus={handleBorder}
              onBlur={handleBlur}
              required
            />
            <div className="button-consult" data-aos="fade-up">
              <button type="submit" style={{ marginTop: '10px' }}>Submit</button>
            </div>
          </form>
          {submissionStatus && <p>{submissionStatus}</p>}
        </div>
        <div className="form-col2" data-aos="fade-up">
          <h1>Get in touch</h1>
          <div className="loc">
            <FontAwesomeIcon icon={faLocationDot} className="con-icon" data-aos="fade-left" />
            <div className="inner-loc">
              <h6>HEAD OFFICE</h6>
              <p>Multigarden B-17, Islamabad</p>
            </div>
          </div>
          <div className="loc">
            <FontAwesomeIcon icon={faPhoneVolume} className="con-icon" data-aos="fade-left" />
            <div className="inner-loc">
              <h6>CALL US</h6>
              <p>+92 343 9805206</p>
            </div>
          </div>
          <div className="loc">
            <FontAwesomeIcon icon={faEnvelope} className="con-icon" data-aos="fade-left" />
            <div className="inner-loc">
              <h6>EMAIL US</h6>
              <p>megatechaisolutions@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
