import React,{useEffect, useState} from 'react'
import './Rate.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
function Rate() {
  const navigate=useNavigate();
    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration
          offset: 100, // Offset from the original trigger point
          once: true, // Whether animation should happen only once - while scrolling down
          easing: 'ease-in-out', // Easing function
        });
      }, []);
      const [showCountUp1, setShowCountUp1] = useState(false);
  const [showCountUp2, setShowCountUp2] = useState(false);
  const [showCountUp3, setShowCountUp3] = useState(false);

  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.5, // Adjust the threshold as needed
  });

  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [ref3, inView3] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView1) setShowCountUp1(true);
    if (inView2) setShowCountUp2(true);
    if (inView3) setShowCountUp3(true);
  }, [inView1, inView2, inView3]);

  return (
    <div className='main-rate-con'>
        <section className='section-rate'>
            <div className='background-overlay'></div>
            <div className='shape'>
                <svg className='svg'>
                    <path ></path>
                </svg>
            </div>

            <div className='text-element' data-aos="fade-up">
               <h1>Transform your visions into reality with our expertise and dedication.</h1>
               <button onClick={()=>{navigate('/about')}}>Discover more</button>
            </div>
           
        </section>
        <div className='progressbar'>
        <div className='background'></div>
        <div className='div1-progress'>
          <div className='content-progress' ref={ref1}>
            {showCountUp1 ? (
              <CountUp end={30} duration={2} suffix="+"  />
            ) : (
              <span>30+</span>
            )}
            <h6>PROJECTS DONE</h6>
          </div>
          <div className='content-progress' ref={ref2}>
            {showCountUp2 ? (
              <CountUp end={20} duration={2} suffix="+" />
            ) : (
              <span>20+</span>
            )}
            <h6>EXPERT TEAM</h6>
          </div>
          <div className='content-progress3' ref={ref3}>
            {showCountUp3 ? (
              <CountUp end={5} duration={2} suffix="+"  />
            ) : (
              <span>5+</span>
            )}
            <h6>YEARS OF EXPERIENCE</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Rate