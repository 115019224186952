import React, {
    useEffect
} from 'react'
import './Work.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume, faHandshake, faBarsProgress, faGifts } from '@fortawesome/free-solid-svg-icons'
import AOS from 'aos';
import 'aos/dist/aos.css';
function Work() {
    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration
          offset: 100, // Offset from the original trigger point
          once: true, // Whether animation should happen only once - while scrolling down
          easing: 'ease-in-out', // Easing function
        });
      }, []);
  return (
    <div className='main-work-con'>
        <div className='inner-work'>
        <section className='section-first'>
            <div className='fs-div1' data-aos="fade-right">
              <div className='fs-div1-inner'>
                <div className='content-div1'>
                  <h6>How it works</h6>
                </div>
                <p>

We follow a structured approach to complete projects: initial consultation, detailed planning, agile development, rigorous testing, and seamless deployment, ensuring top-quality results and client satisfaction at every step.</p>
              </div>
            </div>
          </section>

          <section className='second-section-work'>
            <div className='step-odd'  data-aos="fade-up">
                <FontAwesomeIcon icon={faPhoneVolume} className='icon-work'/>
                <h4>Free Consultation</h4>
                <p>

Our free consultation service allows clients to discuss their project ideas and goals with our expert team. We provide valuable insights and strategic guidance, helping clients shape their vision into a feasible and impactful software solution, ensuring a strong foundation for successful project execution.</p>
            </div>
            <div className='step-even'  data-aos="fade-down">
                <FontAwesomeIcon icon={faHandshake} className='icon-work'/>
                <h4>Breif the Project</h4>
                <p>
We start with a thorough consultation to understand the client's project ideas and objectives. This is followed by detailed planning where we outline the project scope, timelines, and deliverables. We develop comprehensive Software Requirement Specification (SRS) documents to ensure all requirements are clearly defined and agreed upon. Once the SRS is finalized, we sign the contract, establishing a solid foundation for the successful execution of the project.</p>
            </div>
            <div className='step-odd'  data-aos="fade-up">
                <FontAwesomeIcon icon={faBarsProgress} className='icon-work'/>
                <h4>Agile Methodology</h4>
                <p>We implement projects using Agile methodology, where each module is developed and tested sequentially. After completing a module, it undergoes thorough QA and client testing. Once all modules are completed, a comprehensive test of the integrated system ensures overall quality and functionality.</p>
            </div>
            <div className='step-even'  data-aos="fade-down">
                <FontAwesomeIcon icon={faGifts} className='icon-work'/>
                <h4>Deployment</h4>
                <p>
Our deployment process ensures a smooth and seamless transition from development to production. We meticulously plan and execute each step, from final testing to live launch, ensuring minimal disruption and maximum efficiency. Our team provides comprehensive support and monitoring to guarantee optimal performance and immediate resolution of any issues, ensuring that our solutions deliver immediate value to our clients.</p>
            </div>
          </section>
        </div>
    </div>
  )
}

export default Work