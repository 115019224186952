import React from 'react'
import './DescriptionWeb.css';
import Accordion from 'react-bootstrap/Accordion';
function DescriptionCyber() {
    return (
        <div className='main-desc-con'>
            <div className='inner-desc-con'>
                <div className='des-content-con'>
                    <h2>Description</h2>
                    <p>
                        In today's digital landscape, robust cybersecurity is crucial to protecting your assets and maintaining trust with your users. At MegaTech AI Solutions, we offer comprehensive cybersecurity services tailored to your specific project requirements and specifications. Our expertise spans a wide range of security measures, including vulnerability assessments, threat detection, incident response, and risk management.
                        <br /><br />
                        We employ the latest technologies and methodologies to safeguard your systems and data from potential threats. Our team of experts collaborates with you to understand your unique needs and challenges, ensuring that our solutions are both effective and adaptable. Whether you're looking to fortify your existing infrastructure or develop a new security strategy from scratch, we provide the expertise and tools necessary to keep your digital environment secure and resilient.
                        <br /><br />
                        Trust MegaTech AI Solutions to be your partner in navigating the complexities of cybersecurity and protecting your valuable information in an ever-evolving threat landscape.</p>
                    <h2 style={{ marginTop: '30px' }}>What we do</h2>
                    <Accordion id='Accordian-main'>
                        <Accordion.Item eventKey="0" id='Accordian-Item'>
                            <Accordion.Header id='header-accordian'>Risk Assessment and Management</Accordion.Header>
                            <Accordion.Body>
                            We conduct thorough risk assessments to identify vulnerabilities and potential threats to your organization. Our team provides strategic recommendations to manage and mitigate risks, ensuring your business remains secure against evolving cyber threats.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" id='Accordian-Item'>
                            <Accordion.Header id='header-accordian'>Security Audits and Compliance</Accordion.Header>
                            <Accordion.Body>
                            Our comprehensive security audits ensure your systems adhere to industry standards and regulatory requirements. We provide detailed reports and actionable insights to help you achieve and maintain compliance with relevant security regulations.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" id='Accordian-Item'>
                            <Accordion.Header id='header-accordian'>Threat Detection and Response</Accordion.Header>
                            <Accordion.Body>
                            We offer advanced threat detection services to identify and respond to potential security incidents in real-time. Our team uses cutting-edge technologies and strategies to safeguard your assets and ensure swift action in the event of a security breach.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" id='Accordian-Item'>
                            <Accordion.Header id='header-accordian'>Security Training and Awareness</Accordion.Header>
                            <Accordion.Body>
                            We provide tailored security training and awareness programs to educate your employees about best practices and potential threats. By fostering a culture of security awareness, we help reduce the risk of human error and enhance your organization's overall security posture.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className='inner-desc-con2'>
                    <h4>Get free a quote</h4>
                    <form className='form'>
                        <label>Name</label>
                        <input type='text' placeholder='Enter your name' required />
                        <label>Email</label>
                        <input type='email' placeholder='Enter your email' required />
                        <label>Message</label>
                        <textarea placeholder='Enter your message' required />
                        <button type='submit'>Get a quote</button>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default DescriptionCyber