import React from 'react'
import './DescriptionWeb.css';
import Accordion from 'react-bootstrap/Accordion';

function DescriptionAI() {
    return (
        <div className='main-desc-con'>
            <div className='inner-desc-con'>
                <div className='des-content-con'>
                    <h2>Description</h2>
                    <p>At MegaTech AI Solutions, we leverage cutting-edge artificial intelligence technologies to deliver tailored solutions that meet your specific project requirements and specifications. Our AI development services are designed to enhance your business operations, drive innovation, and provide you with a competitive edge in the market.
                        <br /><br />
                        <strong>Customized AI Solutions</strong>
                        <br />
                        We specialize in creating bespoke AI solutions that align perfectly with your business needs. Whether you require machine learning models, natural language processing, computer vision, or predictive analytics, our team is adept at developing and implementing AI technologies that cater to your unique objectives.
                        <br /><br />
                        <strong>Expertise Across Technologies</strong>
                        <br />
                        Our skilled developers utilize a range of advanced technologies to build robust AI systems. From deep learning frameworks like TensorFlow and PyTorch to state-of-the-art algorithms and cloud-based AI services, we ensure that the technology used is the most suitable for your project's demands.
                        <br /><br />
                        <strong>End-to-End AI Development</strong>
                        <br />
                        We offer end-to-end AI development services, encompassing everything from initial consulting and research to design, development, and deployment. Our approach ensures that your AI solutions are seamlessly integrated into your existing systems and deliver actionable insights and value.
                        <br /><br />
                        <strong>Data-Driven Approach</strong>
                        <br />
                        Data is at the heart of our AI development process. We focus on harnessing the power of your data to build models that are accurate, reliable, and capable of driving meaningful business outcomes. Our team excels in data collection, preprocessing, and analysis to ensure that our AI solutions are both effective and efficient.
                        <br /><br />
                        <strong>Commitment to Innovation</strong>
                        <br />
                        At MegaTech AI Solutions, we are committed to pushing the boundaries of what AI can achieve. We stay abreast of the latest advancements and trends in the AI field to ensure that our solutions are not only current but also forward-thinking.
                        <br /><br />
                        Partner with us to transform your business with AI technologies that are tailored to your needs and designed to drive success.</p>
                    <h2 style={{ marginTop: '30px' }}>What we do</h2>
                    <Accordion id='Accordian-main'>
                        <Accordion.Item eventKey="0" id='Accordian-Item'>
                            <Accordion.Header id='header-accordian'>AI Model Development</Accordion.Header>
                            <Accordion.Body>
                            We specialize in developing advanced AI models tailored to your specific needs. From natural language processing to computer vision, our team builds sophisticated models using cutting-edge techniques and algorithms to solve complex problems and enhance decision-making capabilities.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" id='Accordian-Item'>
                            <Accordion.Header id='header-accordian'>Model Deployment</Accordion.Header>
                            <Accordion.Body>
                            Our team ensures the seamless deployment of AI models into production environments. We utilize robust cloud platforms and containerization technologies to guarantee scalable, reliable, and secure deployment, enabling your AI solutions to perform optimally in real-world scenarios.
                            
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" id='Accordian-Item'>
                            <Accordion.Header id='header-accordian'>AI Integration</Accordion.Header>
                            <Accordion.Body>
                            We integrate AI models into existing systems and workflows to enhance functionality and drive innovation. Our expertise ensures that AI solutions are seamlessly incorporated, providing valuable insights and automating processes to improve efficiency and performance.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" id='Accordian-Item'>
                            <Accordion.Header id='header-accordian'>Continuous Monitoring and Optimization</Accordion.Header>
                            <Accordion.Body>
                            We provide ongoing monitoring and optimization services to ensure the continued effectiveness of your AI solutions. By analyzing performance metrics and making data-driven adjustments, we enhance model accuracy and adapt to evolving business requirements.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className='inner-desc-con2'>
                    <h4>Get free a quote</h4>
                    <form className='form'>
                        <label>Name</label>
                        <input type='text' placeholder='Enter your name' required />
                        <label>Email</label>
                        <input type='email' placeholder='Enter your email' required />
                        <label>Message</label>
                        <textarea placeholder='Enter your message' required />
                        <button type='submit'>Get a quote</button>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default DescriptionAI