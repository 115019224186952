const API_URL = process.env.REACT_APP_API_URL;

export const submitContactForm = async (contactData) => {
  try {
    console.log('Sending request to:', `${API_URL}/contacts`); // Log URL
    const response = await fetch(`${API_URL}/contacts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contactData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Response error:', errorData); // Log error data
      throw new Error(`Network response was not ok: ${errorData.message || 'Unknown error'}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error submitting contact form:', error); // Log error
    throw error;
  }
};
