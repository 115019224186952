import React from 'react'
import './DescriptionWeb.css';
import Accordion from 'react-bootstrap/Accordion';

function DescriptionGame() {
  return (
    <div className='main-desc-con'>
            <div className='inner-desc-con'>
                <div className='des-content-con'>
                    <h2>Description</h2>
                    <p>
At our core, we deliver cutting-edge game development solutions using Unity, a leading platform renowned for its versatility and power. Our game development services are designed to bring your creative visions to life, whether you're aiming for immersive 3D worlds, engaging 2D experiences, or interactive simulations. We tailor our approach based on your specific project requirements and specifications, ensuring that each game we create meets your unique goals and expectations. From concept to deployment, our expert team leverages Unity’s robust features to craft high-quality, scalable, and captivating games that stand out in the competitive landscape.</p>
                    <h2 style={{ marginTop: '30px' }}>What we do</h2>
                    <Accordion id='Accordian-main'>
        <Accordion.Item eventKey="0" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Game Design and Concept Development</Accordion.Header>
            <Accordion.Body>
            Our team starts by collaborating with you to understand your vision and objectives. We handle everything from conceptualizing game mechanics to designing engaging storylines, ensuring that your game stands out in the market.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Game Development and Programming</Accordion.Header>
            <Accordion.Body>
            Utilizing Unity technology, our developers bring your game to life with cutting-edge programming and high-quality graphics. We ensure smooth gameplay, responsive controls, and immersive experiences tailored to your target audience.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Quality Assurance and Testing</Accordion.Header>
            <Accordion.Body>
            Our rigorous testing process ensures your game is free of bugs and performance issues. We conduct extensive QA testing to deliver a polished, high-quality product that meets all industry standards.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" id='Accordian-Item'>
            <Accordion.Header id='header-accordian'>Post-Launch Support and Updates</Accordion.Header>
            <Accordion.Body>
            After your game's launch, we provide ongoing support and updates to keep your game fresh and engaging. From fixing bugs to adding new features, we're here to ensure your game continues to thrive.
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
                </div>
                <div className='inner-desc-con2'>
                    <h4>Get free a quote</h4>
                    <form className='form'>
                        <label>Name</label>
                        <input type='text' placeholder='Enter your name' required />
                        <label>Email</label>
                        <input type='email' placeholder='Enter your email' required />
                        <label>Message</label>
                        <textarea placeholder='Enter your message' required />
                        <button type='submit'>Get a quote</button>
                    </form>
                </div>
            </div>

        </div>
  )
}

export default DescriptionGame