import React from 'react'
import GameHeader from '../Components/GameHeader'
import DescriptionGame from '../Components/DescriptionGame'
import Work from '../Components/Work'
import Rate from '../Components/Rate'
import Choose from '../Components/Choose'
import Footer1 from '../Components/Footer1'
function GameServicePg() {
  return (
    <div style={{backgroundColor:'#1C1C1C', width:'100%', height:'max-content'}}>
    <GameHeader/>
    <DescriptionGame/>
   <Work/>
   <Rate/>
   <Choose/>
   <Footer1/>
 
</div>
  )
}

export default GameServicePg