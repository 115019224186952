import React,{useEffect} from 'react'
import './Footer1.css'
import logo from '../Images/logo_mega.png'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import Consult from './Consult'
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { useNavigate } from 'react-router-dom';
function Footer1() {
    const navigate=useNavigate();
    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration
          offset: 100, // Offset from the original trigger point
          once: true, // Whether animation should happen only once - while scrolling down
          easing: 'ease-in-out', // Easing function
        });
      }, []);
    return (
        <div className='main-con-footer'>
           <Consult/>
            <section className='footer-main-content-con'>
                <div className='col1-footer'>
                    <img src={logo} alt='MegaTech AI Solutions' className='footer-logo' data-aos="fade-right"/>
                    <div className='slogan-footer' data-aos="fade-right">
                        <p>Crafting Excellence, Every Project.</p>
                    </div>
                    <div className='social-links'data-aos="fade-up">
                        <FontAwesomeIcon icon={faFacebookF} className='icon-footer' />
                        <FontAwesomeIcon icon={faLinkedinIn} className='icon-footer' />
                        <FontAwesomeIcon icon={faInstagram} className='icon-footer' />
                        <FontAwesomeIcon icon={faYoutube} className='icon-footer' />
                    </div>
                </div>
                <div className='col-footer' data-aos="fade-up">
                    <div className='title-footer'>
                        <h5>Services</h5>
                    </div>
                    <div className='serv-footer'>
                        <a href='/services/web'>Web Development</a>
                        <a href='/services/mobileapp'>App Development</a>
                        <a href='/services/ai'>AI Development</a>
                        <a href='/services/blockchain'>BlockChain Development</a>
                        <a href='/services/game'>Game Development</a>
                        <a href='/services/cyber'>CyberSecurity Services</a>
                    </div>
                </div>
                <div className='col-footer' data-aos="fade-up">
                    <div className='title-footer'>
                        <h5>Support</h5>
                    </div>
                    <div className='serv-footer'>
                        <a href='/contact'>Contact us</a>
                        <a href='/contact'>Customer Services</a>
                        
                    </div>
                </div>

                <div className='col-footer' data-aos="fade-up">
                    <div className='title-footer'>
                        <h5>Company</h5>
                    </div>
                    <div className='serv-footer'>
                        <a href='/about'>About us</a>
                        <a href='/about'>How it works?</a>
                        <a href='/notfound'>Career</a>
                        <a href='/notfound'>Our Projects</a>
                        <a href='/notfound'>Our Team</a>
                        
                    </div>
                </div>
               
            </section>
            <div className='hor-line'></div>
            <div className='copyright'>
                <p>Copyright &copy; 2022 Megatech Ai Solutions, All rights reserved. Powered by Megatech Team.</p>
            </div>
        </div>
    )
}

export default Footer1