import React from 'react'
import WebHeader from '../Components/WebHeader'
import DescriptionWeb from '../Components/DescriptionWeb'
import Footer1 from '../Components/Footer1'
import Work from '../Components/Work'
import Rate from '../Components/Rate'
import Choose from '../Components/Choose'

function WebServicePG() {
  return (
    <div style={{backgroundColor:'#1C1C1C', width:'100%', height:'max-content'}}>
        <WebHeader/>
        <DescriptionWeb/>
        <Work/>
        <Rate/>
        <Choose/>
        <Footer1/>
    </div>
  )
}

export default WebServicePG