import React, {useEffect} from 'react'
import './Contactcmp.css'
import Navbar1 from './Navbar1'
import character from '../Images/contact-3d.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
function Contactcmp() {
    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration
          offset: 100, // Offset from the original trigger point
          once: true, // Whether animation should happen only once - while scrolling down
          easing: 'ease-in-out', // Easing function
        });
      }, []);
  return (
    <div className='main-con-contact'>
        <div className='background-overlay-contact'></div>
        <Navbar1/>
        <div className='head-pg-div' data-aos="fade-right">
            <h1>Contact us</h1>
            <h4>Get in touch and let us know how we can help.</h4>
        </div>
        <div className='character-contact'>
            <img src={character} alt='Contact us' className='character-real' data-aos="fade-up"/>
        </div>
    </div>
  )
}

export default Contactcmp