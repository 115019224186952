import React, {useEffect} from 'react'
import "./Consult.css"
import character from '../Images/character_Consult.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
function Consult() {
  const navigate=useNavigate();
    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration
          offset: 100, // Offset from the original trigger point
          once: true, // Whether animation should happen only once - while scrolling down
          easing: 'ease-in-out', // Easing function
        });
      }, []);
  return (
    <div className='main-con-consult' data-aos="fade-down">
      <div className='inner-consult'>
        <div className='col-consult'>
        <div className='content-div1' data-aos="fade-right">
                  <h6>FREE CONSULTATION</h6>
                </div>
      
        <div >
                  <h2 className='h2-consultant' data-aos="fade-right">Schedule a free consultation now and discover how Megatech can bring your project to life!</h2>
                </div>
                <div className='button-consult' data-aos="fade-up">
                    <button onClick={()=>{navigate('/contact')}}>Get Started</button>
                </div>
                </div>
                <div className='img-col-cons' >
                    <img src={character} alt='Consultant' className='img-cons' data-aos="fade-right"/>
                </div>
      </div>

    </div>
  )
}

export default Consult