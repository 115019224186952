import React,{useEffect} from 'react'
import Navbar1 from './Navbar1'
import bgHeader from '../Images/WebBGImg.jpg'
import './ServicesWeb.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import character from '../Images/tesimonial.png'

function CyberHeader() {
    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration
          offset: 100, // Offset from the original trigger point
          once: true, // Whether animation should happen only once - while scrolling down
          easing: 'ease-in-out', // Easing function
        });
      }, []);
  return (
    <div className='main-cyber-div'>
    <div className='background-overlay-contact'></div>
<Navbar1/>
<div className='head-pg-div' data-aos="fade-right">
        <h1>Comprehensive Cybersecurity Services</h1>
        <h4>To Safeguard Your Digital Assets</h4>
    </div>
    <div className='character-contact-abt'>
        <img src={character} alt='Contact us' className='character-real-abt' data-aos="fade-up"/>
    </div>
</div>
  )
}

export default CyberHeader