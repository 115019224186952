import React from 'react'
import Contactcmp from '../Components/Contactcmp'
import ContactForm from '../Components/ContactForm'
import Footer1 from '../Components/Footer1'

function Contact() {
  return (
    <div style={{backgroundColor:'#1C1C1C', width:'100%', height:'max-content'}}>
<Contactcmp/>
<ContactForm/>
<Footer1/>
        </div>
  )
}

export default Contact