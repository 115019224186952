import React, { useEffect } from 'react';
import './Choose.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenRuler, faPeopleGroup, faLightbulb, faHeadphonesSimple } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Choose() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      offset: 100, // Offset from the original trigger point
      once: true, // Whether animation should happen only once - while scrolling down
      easing: 'ease-in-out', // Easing function
    });
  }, []);

  return (
    <div className='main-choose-con'>
      <div className='choose-inner1'>
        <div className='choose-inner2'>
          <section className='section-first'>
            <div className='fs-div1' data-aos="fade-right">
              <div className='fs-div1-inner'>
                <div className='content-div1'>
                  <h6>WHY CHOOSE US</h6>
                </div>
                <div className='fs-div1-inner2'>
                  <h2>Discover Why We're Your Best Choice for Success</h2>
                </div>
              </div>
            </div>
          </section>

          <section className='section-second'>
            <div className='second-sec-div1'>
              <div className='sec-col1' data-aos="fade-up" data-aos-delay="100">
                <div className='col1-inner1'>
                  <div className='col1-inner2'>
                    <FontAwesomeIcon icon={faPenRuler} className='icon-choose'/>
                    <h4>Professional & Clean Design</h4>
                    <p>Choose us for professional and clean design that enhances your brand's impact and user experience.</p>
                  </div>
                </div>
              </div>
              <div className='sec-col2' data-aos="fade-up" data-aos-delay="200">
                <div className='col2-inner1'>
                  <div className='col2-inner2'>
                    <FontAwesomeIcon icon={faPeopleGroup} className='icon-choose-col2'/>
                    <h4>Experiences & Expert Team</h4>
                    <p>Choose us for our unparalleled expertise and experienced team dedicated to delivering exceptional results.</p>
                  </div>
                </div>
              </div>
              <div className='sec-col1' data-aos="fade-up" data-aos-delay="300">
                <div className='col1-inner1'>
                  <div className='col1-inner2'>
                    <FontAwesomeIcon icon={faLightbulb} className='icon-choose'/>
                    <h4>Creative Solution & Fresh Ideas</h4>
                    <p>Unlock innovative solutions tailored to your needs. Choose us for cutting-edge technology and unmatched creativity.</p>
                  </div>
                </div>
              </div>
              <div className='sec-col2' data-aos="fade-up" data-aos-delay="400">
                <div className='col2-inner1'>
                  <div className='col2-inner2'>
                    <FontAwesomeIcon icon={faHeadphonesSimple} className='icon-choose-col2'/>
                    <h4>Premium Support</h4>
                    <p>Experience unparalleled assistance with our dedicated Premium Support, ensuring your success every step of the way.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Choose;
