import React,{useEffect, useRef} from 'react'
import './Who.css'
import img1 from '../Images/whoimg1.jpg'
import img2 from '../Images/whoimg2.png'
import img3 from '../Images/whoimg3.png'
import img4 from '../Images/whoimg4.png'
import { useNavigate } from 'react-router-dom'
function Who() {
  const navigate=useNavigate();
    useEffect(() => {
        const slideInOnScroll = () => {
          const sec1 = document.querySelector('.inner-sec1');
          const sec2 = document.querySelector('.inner-sec2');
    
          const checkPosition = (element, animationClass) => {
            const elementTop = element.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            if (elementTop < windowHeight - 50) { // Adjust threshold as needed
              element.classList.add(animationClass);
            }
          };
    
          checkPosition(sec1, 'slide-in-left');
          checkPosition(sec2, 'slide-in-right');
        };
    
        // Attach event listener on scroll
        window.addEventListener('scroll', slideInOnScroll);
    
        // Initial check on page load
        slideInOnScroll();
    
        // Clean up listener on component unmount
        return () => {
          window.removeEventListener('scroll', slideInOnScroll);
        };
      }, []);


      const img2Ref = useRef(null);
  const img3Ref = useRef(null);
  const img4Ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;

      const scaleImage = (ref) => {
        if (ref.current) {
          const elementTop = ref.current.getBoundingClientRect().top;
          if (elementTop < windowHeight) {
            const scale = Math.min((scrollY - elementTop + windowHeight) / 500, 1.5);
            ref.current.style.transform = `scaleX(${scale})`;
          }
        }
      };

      const rotateImage = (ref, speed) => {
        if (ref.current) {
          const elementTop = ref.current.getBoundingClientRect().top;
          if (elementTop < windowHeight) {
            const rotation = Math.min((scrollY - elementTop + windowHeight) / speed, 180);
            ref.current.style.transform = `rotate(${rotation}deg)`;
          }
        }
      };


      scaleImage(img2Ref);
      scaleImage(img3Ref);
      rotateImage(img4Ref, 10); // Adjust speed as needed
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.1,
    });

    if (img2Ref.current) observer.observe(img2Ref.current);
    if (img3Ref.current) observer.observe(img3Ref.current);
    if (img4Ref.current) observer.observe(img4Ref.current);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (img2Ref.current) observer.unobserve(img2Ref.current);
      if (img3Ref.current) observer.unobserve(img3Ref.current);
      if (img4Ref.current) observer.unobserve(img4Ref.current);
    };
  }, []);


  return (
    <div className='main-who-con'>
        
        <div className='section1-who'>
            <div className='inner-sec1'>
                <img src={img1} alt='Who we are' className='img-1'/>
            </div>
            <div className='inner-sec2'>
                <h6>WHO WE ARE</h6>
                <h2>Leading the Future of Technology with Innovation and Security.</h2>
                <p>At our company, we specialize in cutting-edge technology solutions that drive innovation and security. Our services encompass web development, mobile app creation, game development, blockchain technology, cybersecurity, and artificial intelligence. We are dedicated to transforming ideas into reality and securing the digital frontier, delivering excellence in every project we undertake.</p>
                <button className='navLink' onClick={()=>{navigate('/about')}}>Learn more</button>
            </div>
            <img src={img2} alt='img2' className='img-2' ref={img2Ref} />
        <img src={img3} alt='img3' className='img-3' ref={img3Ref} />
        <img src={img4} alt='img4' className='img-4' ref={img4Ref} />
        </div>
    </div>
  )
}

export default Who